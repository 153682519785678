import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";


const AboutPage = () => (
    <Layout>
        <SEO title="About" keywords={["about", "happy indra wijaya"]} />
        <div className="about">
            <div className="head">/about</div>

            <div className="hello">Hello World!</div>
            <div className="desc">
                <p>
                    <b>Happy Indra Wijaya</b> is a passionate software engineer from Indonesia.
                    Fueled by strong positive energy to keep learning, forging his skills
                    to deliver quality and value. 
                    Detail orientated individual with disciplines, consistency and integrity.
                    He Believed in simplicity and always love start all the things from scratch.
                </p>
                <p>
                    He is on personal mission to elevate people life and making socioeconomic equality
                    with technology. <span role="img" aria-label="rocket">🚀</span>
                </p>
                <p>
                    If you wish to reach him through a pretty form, sorry to disappointed you as there is none.
                    Just Drop your <span role="img" aria-label="speech_balloon">💬</span> to <a href="mailto:wijaya.happyindra@gmail.com">wijaya.happyindra@gmail.com</a> and we good.
                </p>
                <p>
                    Salam.
                </p>
                
                <a href="https://www.buymeacoffee.com/hiwijaya" target="_blank" rel="noopener noreferrer">
                    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" 
                        className="buymeacoffee" />  
                </a>
            </div>
        </div>
    </Layout>
);
export default AboutPage;
